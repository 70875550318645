<template>
  <div class="main-content q-pa-md">
    <Mural :breadcrumbs="breadcrumbs" route="warehouse" :query="{}">
      <DefaultBtn
        titleButton="Salvar"
        icon="edit"
        @click="addOrUpdateWarehouse"
      />
    </Mural>

    <form class="row page-content">
      <div class="content-thumb-training col-4">
        <div class="col-12 flex row q-pa-sm">
          <div class="col-12">
            <label class="title-form">Foto do Produto</label>
          </div>
          <div class="col-12 thumb-training q-mt-sm shadow-3">
            <q-img
              height="150px"
              :src="imageUrl"
              fit="contain"
              style="background-color: #e5e5e5"
            />
          </div>
          <div
            class="col-12 btn-thumb-training flex justify-between q-mt-md"
          ></div>
        </div>
      </div>
      <div class="row col-8 q-pa-md q-pb-none">
        <div class="col-12">
          <div class="form-input col-6 column">
            <div class="row">
              <span class="title-input-form">Produto</span>
            </div>
            <div class="row">
              <q-select
                outlined
                bg-color="grey-3"
                color="default-pink"
                class="col-10"
                dense
                emit-value
                map-options
                :disable="disabledProduct"
                use-input
                input-debounce="0"
                v-model="warehouse.product_id"
                :options="products"
                @filter="filterFnProducts"
                @update:model-value="showProduct()"
                :rules="[(val) => !!val || 'O campo produto é obrigatório.']"
              />
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="form-input col-6 column">
            <div class="row">
              <span class="title-input-form">Descrição</span>
            </div>
            <div class="">
              <q-item-section>
                <q-item-label v-html="warehouse.product.description" />
              </q-item-section>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 thumb-training">
        <q-item>
          <q-item-section>
            <q-item-label>
              <q-icon name="warehouse" size="sm" />
              Estoque</q-item-label
            >
          </q-item-section>
        </q-item>
        <q-item class="row">
          <div class="col-3">
            <div class="form-input col-6 column">
              <div class="row">
                <span class="title-input-form">Quantidade</span>
              </div>
              <div class="row">
                <q-input
                  class="col-10"
                  bg-color="grey-3"
                  input-debounce="0"
                  v-model.number="warehouse.quantity"
                  color="default-pink"
                  dense
                  type="number"
                  outlined
                  :rules="[
                    (val) => !!val || 'O campo quantidade é obrigatório.',
                  ]"
                />
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="form-input col-6 column">
              <div class="row">
                <span class="title-input-form">Preço</span>
              </div>
              <div class="row">
                <q-input
                  class="col-10"
                  bg-color="grey-3"
                  v-model.number="warehouse.price"
                  color="default-pink"
                  dense
                  type="number"
                  outlined
                  :rules="[(val) => !!val || 'O campo preço é obrigatório.']"
                />
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="form-input col-6 column">
              <div class="row">
                <span class="title-input-form">Preço Antigo</span>
              </div>
              <div class="row">
                <q-input
                  class="col-10"
                  bg-color="grey-3"
                  :disable="true"
                  v-model.number="warehouse.old_price_front"
                  color="default-pink"
                  dense
                  type="number"
                  outlined
                />
              </div>
            </div>
          </div>
          <div class="col-3">
            <div class="form-input col-6 column">
              <div class="row">
                <span class="title-input-form"
                  >Limite de Compra por Usuário</span
                >
              </div>
              <div class="row">
                <q-input
                  class="col-10"
                  bg-color="grey-3"
                  input-debounce="0"
                  v-model.number="warehouse.max_number_orders_user"
                  color="default-pink"
                  dense
                  type="number"
                  outlined
                />
              </div>
            </div>
          </div>
        </q-item>
        <q-separator spaced inset />
      </div>

      <div class="col-12 thumb-training">
        <q-item>
          <q-item-section avatar>
            <q-item-label>
              <q-icon name="price_check" size="sm" />
              Ativar Promoção
              <q-toggle v-model="warehouse.contains_promotion" color="green" />
              (Crie promoções a partir da quantidade produtos que você tem no
              estoque.)</q-item-label
            >
          </q-item-section>
          <q-item-section>
            <q-btn
              color="default-pink"
              class="q-ml-sm"
              text-color="default-pink"
              rounded
              no-caps
              @click="addPromotion()"
              :outline="true"
              :disable="warehouse.promotions.length === 1 ? true : false"
              unelevated
            >
              <p style="margin: 0px 10px">Adicionar Promoção</p>
            </q-btn>
          </q-item-section>
        </q-item>
        <q-list>
          <q-item>
            <q-item-section avatar>
              <q-icon color="default-pink" name="price_check" />
            </q-item-section>
            <div class="col-2">
              <div class="form-input col-6 column">
                <div class="row">
                  <span class="title-input-form">Quantidade</span>
                </div>
              </div>
            </div>
            <div class="col-2">
              <div class="form-input col-6 column">
                <div class="row">
                  <span class="title-input-form">Preço Promocional</span>
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="form-input col-6 column">
                <div class="row">
                  <span class="title-input-form">Data Inicial</span>
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="form-input col-6 column">
                <div class="row">
                  <span class="title-input-form">Data Final</span>
                </div>
              </div>
            </div>
          </q-item>
          <q-item
            class="row"
            :key="index"
            v-for="(promotion, index) in warehouse.promotions"
          >
            <q-item-section avatar>
              {{ promotion.saleOffId }}
            </q-item-section>
            <div class="col-2">
              <div class="form-input col-6 column">
                <div class="row">
                  <q-input
                    class="col-10"
                    bg-color="grey-3"
                    input-debounce="0"
                    v-model.number="promotion.amount"
                    color="default-pink"
                    :disable="!warehouse.contains_promotion"
                    dense
                    type="number"
                    outlined
                  />
                </div>
              </div>
            </div>
            <div class="col-2">
              <div class="form-input col-6 column">
                <div class="row">
                  <q-input
                    class="col-10"
                    bg-color="grey-3"
                    v-model.number="promotion.price"
                    :disable="!warehouse.contains_promotion"
                    color="default-pink"
                    dense
                    type="number"
                    outlined
                  />
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="form-input col-6 column">
                <div class="row">
                  <q-input
                    class="col-10"
                    bg-color="grey-3"
                    v-model.number="promotion.start_date"
                    :disable="!warehouse.contains_promotion"
                    color="default-pink"
                    dense
                    outlined
                  >
                    <template v-slot:prepend>
                      <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy
                          cover
                          transition-show="scale"
                          transition-hide="scale"
                        >
                          <q-date
                            v-model="promotion.start_date"
                            mask="YYYY-MM-DDTHH:mm:ss"
                          >
                            <div class="row items-center justify-end">
                              <q-btn
                                v-close-popup
                                label="Close"
                                color="primary"
                                flat
                              />
                            </div>
                          </q-date>
                        </q-popup-proxy>
                      </q-icon>
                    </template>

                    <template v-slot:append>
                      <q-icon name="access_time" class="cursor-pointer">
                        <q-popup-proxy
                          cover
                          transition-show="scale"
                          transition-hide="scale"
                        >
                          <q-time
                            v-model="promotion.start_date"
                            mask="YYYY-MM-DDTHH:mm:ss"
                            format24h
                          >
                            <div class="row items-center justify-end">
                              <q-btn
                                v-close-popup
                                label="Close"
                                color="primary"
                                flat
                              />
                            </div>
                          </q-time>
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                  </q-input>
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="form-input col-6 column">
                <div class="row">
                  <q-input
                    class="col-10"
                    bg-color="grey-3"
                    v-model.number="promotion.end_date"
                    :disable="!warehouse.contains_promotion"
                    color="default-pink"
                    dense
                    outlined
                  >
                    <template v-slot:prepend>
                      <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy
                          cover
                          transition-show="scale"
                          transition-hide="scale"
                        >
                          <q-date
                            v-model="promotion.end_date"
                            mask="YYYY-MM-DDTHH:mm:ss"
                          >
                            <div class="row items-center justify-end">
                              <q-btn
                                v-close-popup
                                label="Close"
                                color="primary"
                                flat
                              />
                            </div>
                          </q-date>
                        </q-popup-proxy>
                      </q-icon>
                    </template>

                    <template v-slot:append>
                      <q-icon name="access_time" class="cursor-pointer">
                        <q-popup-proxy
                          cover
                          transition-show="scale"
                          transition-hide="scale"
                        >
                          <q-time
                            v-model="promotion.end_date"
                            mask="YYYY-MM-DDTHH:mm:ss"
                            format24h
                          >
                            <div class="row items-center justify-end">
                              <q-btn
                                v-close-popup
                                label="Close"
                                color="primary"
                                flat
                              />
                            </div>
                          </q-time>
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                  </q-input>
                </div>
              </div>
            </div>
            <q-item-section side>
              <q-btn
                dense
                round
                color="default-pink"
                title="Remover"
                flat
                @click="disabled(index)"
                icon="o_delete_outline"
              />
            </q-item-section>
          </q-item>
        </q-list>
      </div>
    </form>

    <q-inner-loading
      :showing="loading"
      label="Por favor aguarde..."
      label-class="text-teal"
      label-style="font-size: 1.1em"
    >
      <div class="collumn justify-center q-my-md">
        <div style="height: auto; width: 50px; margin: auto">
          <LoadingIcon />
        </div>
        <label>Por favor aguarde...</label>
      </div>
    </q-inner-loading>
  </div>
</template>

<script>
import WareHouseService from "@/services/WareHouseService";
import Mural from "@/components/Mural.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import InputNumber from "@/components/shared/inputs/InputNumber.vue";
import LoadingIcon from "@/components/LoadingIcon.vue";

import { ref, reactive, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { ENV } from "@/utils/env";
import { date, useQuasar } from "quasar";

export default {
  name: "NewProductWarehouse",
  components: {
    Mural,
    DefaultBtn,
    InputNumber,
    LoadingIcon,
  },
  setup() {
    let _wareHouseService = new WareHouseService();
    let breadcrumbs = ref(["Adicionar Produto no Estoque"]);
    const imageUrl = ref("");
    let loading = ref(false);
    let products = ref([]);
    let optionProducts = ref([]);
    let route = new useRoute();
    let router = new useRouter();
    let url_aws_bucket = ENV.URL_AWS_BUCKET;
    let $q = new useQuasar();
    let disabledProduct = ref(false);
    let warehouse = reactive({
      product_id: null,
      price: null,
      old_price: 0,
      old_price_front: 0,
      max_number_orders_user: 0,
      quantity: null,
      contains_promotion: false,
      product: {
        description: null,
      },
      promotions: [],
    });

    onMounted(async () => {
      await _getProducts();
      await _getWareHouseProduct();
    });

    async function _getProducts() {
      _wareHouseService
        .getAllProducts()
        .then((response) => {
          response.data.map((item) => {
            products.value.push({
              label: item.name,
              value: item.id,
            });
          });
          optionProducts.value = products.value;
        })

        .catch((e) => {
          console.log("deu ruim " + e);
        });
    }

    async function _getWareHouseProduct() {
      const productId = route.query.productId;
      if (productId != null) {
        disabledProduct.value = true;
        _showLoading();
        let response = await _wareHouseService.getWarehouseProduct(productId);

        warehouse.product_id = response.data.product_id;
        warehouse.price = response.data.price;
        warehouse.old_price = response.data.price;
        warehouse.old_price_front = response.data.old_price;
        warehouse.quantity = response.data.quantity;
        warehouse.max_number_orders_user = response.data.max_number_orders_user;
        warehouse.product.description = response.data.product.description;
        warehouse.contains_promotion = response.data.contains_promotion;

        if (warehouse.contains_promotion) {
          response.data.promotions.forEach((promotion) => {
            warehouse.promotions.push(promotion);
          });
        }

        response.data.product.images.forEach((image) => {
          imageUrl.value = url_aws_bucket + image.path;
        });

        _hideLoading();
      }
    }

    function addPromotion() {
      warehouse.promotions[0] = {
        amount: null,
        price: null,
        start_date: null,
        end_date: null,
      };
    }

    async function addOrUpdateWarehouse() {
      const productId = route.query.productId;
      _showLoading();

      await _wareHouseService
        .addWarehouse(warehouse)
        .then((response) => {
          loading.value = false;
          router.push({ name: "warehouse" });
        })
        .catch((error) => {
          $q.notify({
            message: error.response.data.toString(),
            color: "red-9",
            position: "top",
          });
          loading.value = false;
        });

      _hideLoading();
    }

    function _showLoading() {
      loading.value = true;
    }

    function _hideLoading() {
      loading.value = false;
    }

    function filterFnProducts(val, update) {
      if (val === "") {
        update(() => {
          products.value = optionProducts.value;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        products.value = optionProducts.value.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    }

    async function showProduct() {
      let response = await _wareHouseService.getProduct(warehouse.product_id);

      warehouse.product.description = response.data.description;

      response.data.images.forEach((image) => {
        imageUrl.value = url_aws_bucket + image.path;
      });
    }

    function disabled(index) {
      $q.notify({
        message: `Deseja remover a promoção ?`,
        color: "red-9",
        position: "bottom",
        actions: [
          {
            label: "Remover",
            color: "white",
            handler: async () => {
              _removePromotion(index);
            },
          },
        ],
      });
    }

    function _removePromotion(index) {
      warehouse.promotions.splice(index, 1);
    }
    return {
      breadcrumbs,
      warehouse,
      loading,
      imageUrl,
      products,
      disabledProduct,
      disabled,
      addPromotion,
      addOrUpdateWarehouse,
      filterFnProducts,
      showProduct,
    };
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  width: 100%;
  height: 100%;

  .page-content {
    height: 80vh;
    overflow-y: auto;
  }
  .title-form {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    opacity: 0.45;
  }

  .content-new-training {
    height: 68vh;

    .content-thumb-training {
      height: 100%;
      .thumb-training {
        border-radius: 10px;
        .q-img {
          border-radius: 10px;
        }
      }
      .btn-thumb-training {
        .q-btn {
          text-transform: none !important;
          border-radius: 8px;
        }
      }
      .label-title-menus {
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        color: #686868;
      }
    }
    .content-form-training {
      height: 100%;

      .form-input {
        .title-input-form {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
          opacity: 0.45;
        }
      }
    }
  }

  @media (min-width: 800px) {
    flex-direction: row;
  }
}
</style>
